import React from 'react';
import './HeroSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="content">
        <h1>Start organize <br/> your daily tasks</h1>
        <p>Experience seamless, user-friendly messaging that <br/> brings people together effortlessly.</p>
        <a href="#feature-section" className="download-button">Download Right Now</a>
        <div className='content-stats'>
          <div className='customers'>
            <img src="/userProfile.png" alt="userProfilePhotos" />
            <div className='customers-stats'>
              <h3>100+</h3>
              <p>Happy Customers</p>
            </div>
          </div>
          <div className='rating'>
            <h3>4.8/5</h3>
            <div className='rating-bottom'>
              <div className='rating-stars'>
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
              </div>
              <p>Rating</p>
            </div>
           
          </div>
        </div>
      </div>
      <div className="hero-image">
        <img src="/Shot.png" alt="Messaging App" />
      </div>
    </div>
  );
}

export default HeroSection;
