import React from 'react';
import './TermsModal.css';

interface TermsModalProps {
    show: boolean;
    handleClose: () => void;
  }
  
  const TermsModal: React.FC<TermsModalProps> = ({ show, handleClose }) => {
    if (!show) {
      return null;
    }

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Terms & Conditions</h2>
          <button className="close-button" onClick={handleClose}>X</button>
        </div>
        <div className="modal-body">
          <iframe src="/terms-and-conditions.html" title="Terms & Conditions"></iframe>
        </div>
      </div>
    </div>
  );
}

export default TermsModal;
