import React from 'react';
import './Header.css';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src="/logo1.png" alt="Logo" />
        <h2>RemindMe</h2>
      </div>
      <nav>
      <ul>
          <li><a href="remindme.website">Home</a></li>
          <li>
            <Link to="feature-section" smooth={true} duration={500}>
              Features
            </Link>
          </li>
          <li>
            <Link to="download-section" smooth={true} duration={500}>
              Download
            </Link>
          </li>
          <li>
            <Link to="review-section" smooth={true} duration={500}>
              Reviews
            </Link>
          </li>
          <li>
            <Link to="footer-section" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div className="auth-buttons">
        <button className="login">Forget</button>
        <button className="signup">No More</button>
      </div>
    </header>
  );
}

export default Header;
