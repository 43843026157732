import React from 'react';
import './Testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const testimonials = [
  {
    quote: "This app has been a lifesaver! I no longer worry about missing important document renewals.",
    name: "Sarah Johnson",
  },
  {
    quote: "Great app for keeping track of my car's maintenance and insurance documents. Highly recommended!",
    name: "Michael Brown",
  },
  {
    quote: "I love the reminders for my house bills and documents. It's like having a personal assistant!",
    name: "Jessica Williams",
  },
  {
    quote: "Very user-friendly and efficient. Helps me stay on top of all my important tasks.",
    name: "David Miller",
  },
  {
    quote: "Finally, an app that reminds me of all my document renewals without any hassle. Thank you!",
    name: "Emily Davis",
  },
  {
    quote: "Setting reminders for my personal documents has never been easier. Excellent app!",
    name: "James Wilson",
  },
  {
    quote: "The notifications are timely and very helpful. I never miss an important date anymore.",
    name: "Ashley Martinez",
  },
  {
    quote: "Perfect for managing both my car and house documents. Keeps everything in one place.",
    name: "Christopher Anderson",
  }
];

const Testimonials: React.FC = () => {
  return (
    <section id="review-section" className="testimonials-section">
      <div className="testimonials-header">
        <h2>Over 100 positive reviews</h2>
        <p>Indulge in sophistication with our luxury collection, showcasing high-end vehicles that elevate your travel experience.</p>
      </div>
      <div className="testimonials-grid">
        {testimonials.map((testimonial, index) => (
          <div key={index} className={`testimonial-card card-${index % 8}`}>
            <div className="stars">
              {[...Array(5)].map((_, i) => (
                <FontAwesomeIcon key={i} icon={faStar} />
              ))}
            </div>
            <p className="quote">"{testimonial.quote}"</p>
            <div className="author">
              <p className="name">{testimonial.name}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
