import React from 'react';
import './DownloadSection.css';
import appStoreLogo from './appStore.png';
import googlePlayLogo from './magazinPlay.png';

const DownloadSection = () => {
  return (
    <div  className="download-section">
      <div>
        <h2>Ready to use RemindMe?</h2>
        <p>Explore the endless possibilities of organizing your life with RemindMe.<br/> Download now from the App Store or Google Play.</p>
      </div>
      
      <div className="download-buttons">
        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer" className="app-store-button">
          App Store
        </a>
        <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer" className="google-play-button">
          Google Play
        </a>
      </div>
    </div>
  );
}

export default DownloadSection;
