import React, { useState } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import TermsModal from './TermsModal';

const Footer: React.FC = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleTermsClick = () => {
    setShowTerms(true);
  };

  const handleCloseModal = () => {
    setShowTerms(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    const url = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSclVWwd-3K364iLH6zOyj8G_SkoiqCOhj7GVUNJOVszyndcxg/formResponse";

    fetch(url, {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    }).then(() => {
      // Reset form
      setFullName('');
      setEmail('');
      setMessage('');
    }).catch(err => console.error('Error:', err));
  };

  return (
    <footer id="footer-section" className="footer">
      <div className="footer-content">
        <div className="book-demo">
          <h2>Leave a feedback</h2>
          <p>We'd love to give you a quick demo of how Events by Sycamore can help make your event a success. Reach out to us by filling the form below.</p>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                name="entry.xxxxxxx" // Replace with your Google Form field name for full name
                placeholder="Full Name *"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
              <input
                type="email"
                name="entry.xxxxxxx" // Replace with your Google Form field name for email
                placeholder="Email Address *"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <textarea
              name="entry.xxxxxxx" // Replace with your Google Form field name for message
              placeholder="Leave a message *"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <button type="submit">Send it</button>
          </form>
        </div>
        <div className="quick-links">
          <h2>Quick Links</h2>
          <ul>
            <li><a href="/terms-and-conditions">Privacy Policy</a></li>
            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
            <li><a href="remindme.website">Our Website</a></li>
          </ul>
          <div className="social-media">
            <a href="https://www.instagram.com/filip_szabo?igsh=MTRsNjBvaWhnbnY0Mg%3D%3D&utm_source=qr"><FontAwesomeIcon icon={faInstagram} /></a>
            <a href="https://x.com/filip_szabo04"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://www.linkedin.com/in/filip-szabo/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
          </div> 
        </div>
        <div className="get-in-touch">
          <h2>Get in Touch</h2>
          <p><a href="mailto:filipszabo.dev@gmail.com">filipszabo.dev@gmail.com</a></p>
          <p><a href="tel:+40743663264">+40 743 663 264</a></p>
          <p>Romania, Arad, Arad</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2024. RemindMe. All rights reserved.</p>
      </div>
      <TermsModal show={showTerms} handleClose={handleCloseModal} />
    </footer>
  );
}

export default Footer;
