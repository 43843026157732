import React from 'react';
import './FeatureSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faLock, faBell } from '@fortawesome/free-solid-svg-icons';

const FeatureSection = () => {
  return (
    <div id="feature-section" className="feature-section">
      <h2>Features for a better experience</h2>
      <div className="feature-list">
        <div className="feature">
          <div className="icon-circle1">
            <FontAwesomeIcon icon={faCalendarCheck} />
          </div>
          <div className="feature-text">
            <h3>Organize your day</h3>
            <p>Effortlessly organize your day using our app, making it simple to keep track of your tasks and events.</p>
          </div>
        </div>

        <div className="feature">
          <div className="icon-circle3">
            <FontAwesomeIcon icon={faBell} />
          </div>
          <div className="feature-text">
            <h3>Quick Reminders</h3>
            <p>Set reminders easily to get notified exactly when you need, ensuring you never miss important events.</p>
          </div>
        </div>

        <div className="feature">
          <div className="icon-circle2">
            <FontAwesomeIcon icon={faLock} />
          </div>
          <div className="feature-text">
            <h3>Keep Safe & Private</h3>
            <p>Rest assured your data is secure with Firestore, providing top-notch encryption and privacy protection.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
