import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import HeroSection from './HeroSection';
import FeatureSection from './FeatureSection';
import DownloadSection from './DownloadSection';
import Footer from './Footer';
import Testimonials from './ReviewSection/ReviewSection';
import TermsPage from './TermsPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <FeatureSection />
              <DownloadSection />
              <Testimonials />
            </>
          } />
          <Route path="/terms-and-conditions" element={<TermsPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;